import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Spin } from "antd";
import classnames from "classnames";
interface IPDPSpinnerProps extends Partial<IPDPSpinnerState> {
  delay: number;
}
export interface IPDPSpinnerHandles extends IPDPSpinnerState {}
interface IPDPSpinnerState {
  visible: boolean;
}
const PDPSpinnerComponent: React.RefForwardingComponent<
  IPDPSpinnerHandles,
  IPDPSpinnerProps
> = (props, ref) => {
  const [state, dispatch] = useState<IPDPSpinnerState>({
    visible: props.visible === true,
  });
  useImperativeHandle(ref, () => ({
    get visible(): boolean {
      return state.visible;
    },
    set visible(visible: boolean) {
      state.visible = visible;
      dispatch({
        visible,
      });
    },
  }));
  return (
    <Spin
      spinning={true}
      delay={props.delay}
      className={classnames("pdp-spinner", {
        hidden: state.visible !== true,
      })}
    ></Spin>
  );
};
export const PDPSpinner = forwardRef(PDPSpinnerComponent);
