import { IPDPMenu } from "../core";
export const PDPMenus: IPDPMenu[] = [
  {
    text: "How It Works",
    path: "how-it-works",
    placement: "LEFT",
    iconClass:"icon-home",
  },
  {
    text: "Documentation",
    path: "documentation",
    placement: "LEFT",
    iconClass:"icon-documentation",
  },
  {
    text: "APIs",
    path: "apis",
    placement: "LEFT",
    iconClass:"icon-apis",
  },
  {
    text: "FAQ",
    path: "faq",
    placement: "LEFT",
    iconClass:"icon-faq",
  },
  {
    text: "Contact Us",
    path: "contact-us",
    placement: "LEFT",
    iconClass:"icon-contact-us",
  },
  //{
  //    text: "Register",
  //    path: "register",
  //    placement: "RIGHT",
  //    condition: "not-authenticated",
  //},
  //{
  //    text: "Login",
  //    path: "login",
  //    placement: "RIGHT",
  //    condition: "not-authenticated",
  //},
];
