import React from "react";
import { IPDPRoute } from "../core";
export const PDPRoutes: IPDPRoute[] = [
  {
    path: "/",
    title: "How It Works",
    component: React.lazy(() => import("../views/HIW")),
  },
  {
    path: "/apis",
    title: "APIs",
    component: React.lazy(() => import("../views/APIs")),
  },
  {
    path: "/how-it-works",
    title: "How It Works",
    component: React.lazy(() => import("../views/HIW")),
  },
  {
    path: "/documentation",
    title: "Documentation",
    component: React.lazy(() => import("../views/Docs")),
  },
  {
    path: "/faq",
    title: "Frequently Asked Questions",
    component: React.lazy(() => import("../views/FAQ")),
  },
  {
    path: "/contact-us",
    title: "Contact Us",
    component: React.lazy(() => import("../views/Contact")),
  },
];
