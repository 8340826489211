import React, { Suspense, useState, useRef } from "react";
import Master from "./main/Master";
import { BrowserRouter } from "react-router-dom";
import {
  PDPSpinner,
  IPDPContextState,
  PDPContext,
  PDPContextInitial,
  IPDPSpinnerHandles,
} from "./main";
import { Spin } from "antd";
const App: React.FunctionComponent = () => {
  const [state, setState] = useState<IPDPContextState>(PDPContextInitial);
  const spinnerRef = useRef<IPDPSpinnerHandles>(null);
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <Spin spinning={true} delay={100} className="pdp-spinner"></Spin>
        }
      >
        <PDPContext.Provider
          value={Object.assign(
            { ...state },
            {
              dispatch: (context: Partial<IPDPContextState>) => {
                setState(Object.assign({ ...state }, context));
              },
              showLoading: () => {
                spinnerRef.current!.visible = true;
              },
              hideLoading: () => {
                spinnerRef.current!.visible = false;
              },
            }
          )}
        >
          <Master />
          <PDPSpinner ref={spinnerRef} delay={100} visible={false}></PDPSpinner>
        </PDPContext.Provider>
      </Suspense>
    </BrowserRouter>
  );
};
export default App;
