import { Drawer } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { IPDPMenu } from "../core";
import { PDPMenu } from "./PDPMenu";
interface IPDPMobileMenuProps extends Partial<IPDPMobileMenuState> {
  config: IPDPMenu[];
  onClick: (menu: string) => void;
}
export interface IPDPMobileMenuHandles extends IPDPMobileMenuState {}
interface IPDPMobileMenuState {
  visible: boolean;
}
const PDPMobileMenuComponent: React.RefForwardingComponent<
  IPDPMobileMenuHandles,
  IPDPMobileMenuProps
> = (props, ref) => {
  const [state, dispatch] = useState<IPDPMobileMenuState>({
    visible: props.visible === true,
  });
  useImperativeHandle(ref, () => ({
    get visible(): boolean {
      return state.visible;
    },
    set visible(visible: boolean) {
      state.visible = visible;
      dispatch({
        visible,
      });
    },
  }));
  return (
    <Drawer
      visible={state.visible}
      className="menu-mobile-drawer"
      title="Developer Portal"
      onClose={() => {
        state.visible = false;
        dispatch({
          visible: false,
        });
      }}
    >
      <PDPMenu
        config={[
          {
            path: "/",
            text: "Home",
            placement: "LEFT",
          },
          ...props.config,
        ]}
        onClick={(menu: string) => {
          props.onClick(menu);
          state.visible = false;
          dispatch({
            visible: false,
          });
        }}
        mode="vertical"
      ></PDPMenu>
    </Drawer>
  );
};
export const PDPMobileMenu = forwardRef(PDPMobileMenuComponent);
