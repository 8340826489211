import { Button, Layout, Row, Col, Typography, Popover } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useRef } from "react";
import { MdMenu } from "react-icons/md";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IPDPMobileMenuHandles, PDPMenu, PDPMobileMenu } from "./comps";
import { PDPMenus, PDPRoutes } from "./config";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import "./theme/theme.css";
import { usePDPContext } from "./core";
import { Link } from "react-router-dom";
const Master: React.FunctionComponent<RouteComponentProps> = (props) => {
  const { Header, Content, Footer } = Layout;
  const bp = useBreakpoint();
  const location = useLocation();
  const context = usePDPContext();

  const mobileMenuRef = useRef<IPDPMobileMenuHandles>(null);

  const onMenuClick = (menu: string) => {
    props.history.push(menu);
  };

  const getTitle = (): React.ReactNode => {
    const route = PDPRoutes.find((r) => {
      return r.path === location.pathname;
    });
    if (route && route.title) {
      return (
        <Col span={24}>
          <Typography.Title level={3} className="view-title">
            {route.title}
          </Typography.Title>
        </Col>
      );
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    console.log(
      PDPMenus.filter((menu) => {
        return menu.placement === "LEFT";
      })
    );
    const route = PDPRoutes.find((r) => {
      return r.path === location.pathname;
    });
    if (route && route.requireLogin && !context.authenticated) {
      props.history.push("/");
    }
  }, []);

  return (
    <React.Fragment>
      <ToastContainer
        newestOnTop={true}
        pauseOnHover={true}
        pauseOnFocusLoss={false}
        closeOnClick={true}
        autoClose={5000}
        draggable={false}
        position={
          bp.xs === true || (bp.sm === true && bp.md !== true)
            ? "top-center"
            : "top-right"
        }
      ></ToastContainer>
      <Layout className="wrapper">
        <Header className="pdp-header">
          <div>
            <div className="logo">
              <Button type="link" onClick={() => props.history.push("/")}>
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/assets/logo.svg`}
                ></img>
              </Button>
            </div>
            <div className="menu-left">
              <ul className="menu" role="menu">
                {PDPMenus.map((value) => {
                  return (
                    <li role="menuitem">
                      <Link to={value.path} className={value.iconClass}>{value.text}</Link> 
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="menu-right">
            {context.authenticated === true && (
              <Popover
                trigger="hover"
                title=""
                content={
                  <Button
                    type="link"
                    onClick={() => {
                      context.dispatch({
                        authenticated: false,
                        profile: undefined,
                      });
                      props.history.push("/");
                    }}
                  >
                    Logout
                  </Button>
                }
              >
                <Typography.Text className="profile">{`${
                  context.profile!.firstName
                } ${context.profile!.lastName}`}</Typography.Text>
              </Popover>
            )}
            {context.authenticated !== true && (
              <PDPMenu
                config={PDPMenus.filter((menu) => {
                  return menu.placement === "RIGHT";
                })}
                onClick={onMenuClick}
              ></PDPMenu>
            )}
          </div>
          <div className="menu-mobile">
            <Button
              icon={<MdMenu></MdMenu>}
              onClick={() => {
                mobileMenuRef.current!.visible = true;
              }}
            ></Button>
          </div>
        </Header>
        <Content className="pdp-content">
          <Row>
            {getTitle()}
            <Col span={24}>
              <Switch>
                {PDPRoutes.filter((route) => {
                  return !context.authenticated ? !route.requireLogin : true;
                }).map((route) => {
                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      exact={true}
                      render={(p) => {
                        const ViewComponent = route.component;
                        return <ViewComponent {...p}></ViewComponent>;
                      }}
                    ></Route>
                  );
                })}
              </Switch>
            </Col>
          </Row>
        </Content>
        <Footer className="pdp-footer"></Footer>
      </Layout>
      <PDPMobileMenu
        ref={mobileMenuRef}
        config={PDPMenus.filter((menu) => {
          return (
            context.authenticated !== true ||
            menu.condition !== "not-authenticated"
          );
        })}
        onClick={onMenuClick}
      ></PDPMobileMenu>
    </React.Fragment>
  );
};
export default withRouter(Master);
