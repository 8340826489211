import React from "react";
import { Menu } from "antd";
import { IPDPMenu, usePDPContext } from "../core";
import { ClickParam } from "antd/lib/menu";
interface IPDPMenuProps {
  config: IPDPMenu[];
  mode?: "horizontal" | "vertical";
  onClick: (menu: string) => void;
}
const PDPMenuComponent: React.FunctionComponent<IPDPMenuProps> = (props) => {
  const context = usePDPContext();
  return (
    <Menu
      mode={props.mode ? props.mode : "horizontal"}
      onClick={(param: ClickParam) => {
        props.onClick(param.key);
      }}
    >
      {props.config
        .filter((menu) => {
          return (
            !menu.condition ||
            (menu.condition === "authenticated" &&
              context.authenticated === true) ||
            (menu.condition === "not-authenticated" &&
              context.authenticated === false)
          );
        })
        .map((menu) => {
          return <Menu.Item key={menu.path}>{menu.text}</Menu.Item>;
        })}
    </Menu>
  );
};
export const PDPMenu = PDPMenuComponent;
