import React, { useContext } from "react";
import { IPDPContext, IPDPContextState } from "./models";
export const PDPContextInitial: IPDPContextState = {
  authenticated: false,
};
export const PDPContext = React.createContext<IPDPContext>({
  ...PDPContextInitial,
  dispatch: () => {},
  showLoading: () => {},
  hideLoading: () => {},
});

export function usePDPContext(): IPDPContext {
  return useContext<IPDPContext>(PDPContext);
}
